<template>
  <Layout>
    <v-main class="pt-4 pb-10 mb-5" v-if="loaded">
      <div id="slide" class="px-3">
        <section-header
          :header="event.subtitle"
          :sub-header="event.title"
        />
      </div>
    </v-main>
    <v-container v-if="loaded">
      <v-row align="start" justify="center">
        <v-col cols="10" sm="4">
          <v-card max-width="270" class="mx-auto">
            <img :src="event.photo_url" width="270"/>
            <v-card-actions>
              <v-btn text color="primary" :to="`/event/register/${event.id}`">
                <v-icon small class="mr-1">mdi-pencil</v-icon> Inscripciones
              </v-btn>
              <v-spacer></v-spacer>
              <!-- <v-btn icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn> -->
            </v-card-actions>
          </v-card>

        </v-col>
        <v-col cols="12" sm="7">
          <p  >
            <span class="title primary--text" v-html="event.title"></span><br/>
            <span class="info--text" v-html="event.subtitle"></span><br/>
            <span class="info--text" v-html="event.venue"></span>
          </p>
          <v-divider class="my-2"></v-divider>
          <h2 class="font-weight-black">DESCRIPCIÓN</h2>
          <p>
             <span class="" v-html="event.description"></span><br/>
          </p>
          <v-divider class="my-2"></v-divider>
          <h2 class="font-weight-black">INFORMACIÓN GENERAL</h2>
          <p>
             <span class="" v-html="event.information"></span><br/>
          </p>
          <v-divider class="my-2"></v-divider>
          <h2 class="font-weight-black">INVITADOS</h2>
          <p>
             <span class="" v-html="(event.speaker == null) ? 'Próximamente' : event.speaker"></span><br/>
          </p>
          <v-divider class="my-2"></v-divider>
          <h2 class="font-weight-black">TARIFAS DE INSCRIPCIÓN</h2>
          <div v-if="event.free" class="pb-5">
            <h3 class="pb-5">EVENTO GRATUITO</h3>
          </div>
          <div v-else>
            <div v-if="fees.length > 0">
              <template v-for="(item, index) in fees">
                <v-list-item :key="index" :class="(index%2) ? 'blue-grey lighten-5' : 'white'">
                  <v-list-item-content>
                    <v-list-item-content v-text="item.description" class="accent--text"/>
                  </v-list-item-content>
                <v-list-item-action>
                  <span class="accent--text">{{event.currency}} {{ formatedPrice(item.price) }}</span>
                </v-list-item-action>
                </v-list-item>
              </template>
            </div>
            <div v-else>
              <p>Próximamente</p>
            </div>
            <div v-if="activities.length > 0">
              <v-divider class="my-2"></v-divider>
              <h2 class="font-weight-black">TARIFAS DE ACTIVIDADES ADICIONALES</h2>
              <template v-for="(item, idx) in activities">
                <v-list-item :key="idx" :class="(idx%2) ? 'blue-grey lighten-5' : 'white'">
                  <v-list-item-content>
                    <v-list-item-content v-text="item.description" class="accent--text"/>
                  </v-list-item-content>
                <v-list-item-action>
                  <span class="accent--text">{{ event.currency }} {{ formatedPrice(item.price) }}</span>
                </v-list-item-action>
                </v-list-item>
              </template>
            </div>

            <v-divider class="my-2"></v-divider>
            <h2 class="font-weight-black">FORMA DE PAGO</h2>
            <p>
              <span class="" v-html="event.payment_options"></span><br/>
            </p>

            <div class="mb-5" v-if="event.paypal">
              <v-divider class="my-2"></v-divider>
              <h2 class="font-weight-black">PAGAR CON PAYPAL</h2>
              <div class="my-4 text-center pa-2">
                <a :href="event.paypal" target="_blank">
                  <v-img class="mb-2" src="/static/paypal.png" height="40" contain />
                  <span class="px-4 py-2 primary white--text rounded elevation-2"><v-icon class="mr-2">mdi-paypal</v-icon>PAGAR</span>
                </a>
              </div>
            </div>

            <div v-if="event.zelle_name">
              <v-divider class="my-2"></v-divider>
              <h2 class="font-weight-black">PAGO CON ZELLE</h2>
              <h4>Enviar pago a:</h4>
              <p>
                <span class="" v-text="event.zelle_name" /><br/>
                <span class="" v-text="event.zelle_email" /><br/>
              </p>
            </div>




          </div>


          <v-divider class="my-2"></v-divider>
          <h2 class="font-weight-black">INFORMACIÓN DE ALOJAMIENTO</h2>
          <p>
             <span class="" v-html="event.accommodation"></span><br/>
          </p>
          <v-divider class="my-2"></v-divider>
          <h2 class="font-weight-black">PROGRAMA</h2>
            <div v-if="programUrl" class="my-1">
                <v-btn class="accent--text" outlined target="_blank" :href="event.program_url">Descargar Programa</v-btn>
            </div>
            <div v-else>
              <span>Próximamente</span>
            </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center my-10">
          <v-btn x-large dark color="primary" :to="`/event/register/${event.id}`">
            <v-icon class="mr-2">mdi-pencil</v-icon> INSCRIPCIONES
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right my-10">
          <v-btn outlined color="blue-grey lighten-3" :to="`/login-operador/${$route.params.id}`">
            Operador
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-main v-if="!loaded" fill-height class="mb-12">
      <v-container class="mb-12 pb-12">
			<v-layout flex align-center justify-center>
				<v-progress-circular indeterminate :size="100" :widht="45" color="secondary" />
			</v-layout>

      </v-container>
		</v-main>
  </Layout>
</template>

<script>
  import { db } from '@/main'
  import numeral from "numeral";

  export default {
    name: 'evento',
    mounted(){
      this.getData()
    },
    data: () => ({
      event: {},
      fees: [],
      activities: [],
      loaded: false,
    }),
    methods: {
      formatedPrice(price) {
        return numeral(price).format('00,000.00');
      },
      async getData() {
        const id = this.$route.params.id

        let arrayEvent = {}
        await db.collection('events').doc(id).get().then((res) => {
          if (res.exists) {
            if(res.data().active){
              arrayEvent = res.data()
            } else {
              this.$router.push('/')
              return
            }
          } else {
            console.log("No such document!");
          }
        });

        let arrayFees = []
        await db.collection('events').doc(id).collection('fees').get().then((snap) => {
          snap.forEach(function(doc) {
            arrayFees.push(doc.data())
          });

        });

        let arrayActivities = []
        await db.collection('events').doc(id).collection('activities').get().then((snap) => {
          snap.forEach(function(doc) {
            arrayActivities.push(doc.data())
          });
        });
        arrayFees.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        arrayActivities.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        this.event = arrayEvent
        this.fees = arrayFees
        this.activities = arrayActivities
        this.loaded = true

      }

    },
    computed: {
      programUrl(){
				return this.event.program_url && this.event.program_url.length > 0;
			},
    }
  }
</script>

<style lang="scss" scoped>

</style>
